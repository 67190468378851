<template>
  <div v-if="isVisible" class="z-50 bg__fundo modal">
    <div class="bg-white box__modal">
      <!------inicio dentro do modal------>
      <div class="container__modal bg-white">
        <header
          class="flex items-center justify-between md:justify-end md:px-5 px-2"
        >
          <div class="md:hidden flex items-center" @click="closeModal">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-red-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
              />
            </svg>
            <span class="text-gray-800 mx-2"> voltar</span>
          </div>

          <button @click="closeModal" class="hidden md:block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-8 w-8 text-red-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </header>

        <main>
          <div class="h-full md:flex items-center justify-center hidden">
            <img
              v-if="product.image"
              :src="product.image"
              :alt="product.description"
              style="max-height: 100%; max-width: 100%"
            />

            <div
              v-else
              class="
                w-24
                h-24
                rounded-full
                border border-gray-100
                flex flex-col
                items-center
                justify-center
                bg-gray-100
                hover:bg-gray-300
              "
            >
              <p
                class="text-gray-500 font-extrabold"
                style="font-family: 'Gluten', cursive"
              >
                putz
              </p>
            </div>
          </div>
          <!-- <div
            class="
              h-full
              md:hidden
              items-center
              justify-center
              image__prod__mobile
            "
            :style="{ backgroundImage: 'url(' + product.image + ')' }"
          ></div> -->
        </main>

        <aside class="dish-content__scrolling">
          <div
            class="
              h-3/6
              md:hidden
              items-center
              justify-center
              image__prod__mobile
            "
            :style="{ backgroundImage: 'url(' + product.image + ')' }"
          ></div>

          <div class="">
            <div
              class="
                text-center
                md:text-left
                mb-3
                font-medium
                line-clamp-2
                md:line-clamp-3
              "
            >
              <p class="name__product font-semibold ">{{ product.name }}</p>
            </div>
            <div
              class="my-3 text-sm text-gray-500 line-clamp-2 md:line-clamp-3"
            >
              <p class="description__product">{{ product.description }}</p>
            </div>
            <div class="my-4">
              <span class="font-normal">R$</span>
              {{ product.price | formatprice }}
            </div>
          </div>

          <div>
            <label
              for="about"
              class="block text-sm font-medium text-gray-500 mb-2"
            >
              Alguma observação?
            </label>
            <div class="mt-1">
              <textarea
                v-model="comment"
                id="about"
                name="about"
                rows="3"
                class="
                  my-2
                  text-gray-400
                  shadow-sm
                  resize-none
                  focus:ring-indigo-500 focus:border-indigo-500
                  mt-1
                  block
                  w-full
                  sm:text-sm
                  text-xs
                  border-gray-300
                  rounded-md
                "
                placeholder="EX: tirar a cebola, maionese à parte etc..."
              ></textarea>
            </div>
          </div>
          <!--complements-->

          <div
            class="my-5"
            v-for="(complement, indexComplement) in listComplements"
            :key="indexComplement"
          >
            <div
              class="
                bg-gray-100
                py-4
                px-3
                text-gray-800
                flex
                items-center
                justify-between
              "
            >
              <div>
                <p class="text-gray-900">{{ complement.name }}</p>

                <p
                  v-if="complement.customEnableSelectedQuantity === 1"
                  class="text-sm text-gray-600"
                >
                  Escolha 1 opção.
                </p>

                <p
                  v-else-if="complement.qtd_max > 1"
                  class="text-sm text-gray-600"
                >
                  Escolha até {{ complement.qtd_max }} opções.
                </p>
                <p v-else class="text-sm text-gray-600">
                  Escolha {{ complement.qtd_min }} opção.
                </p>
              </div>

              <div
                v-if="CheckIfYouHaveSelectedRequiredItems(complement)"
                class="space-x-2"
              >
                <span class="bg-gray-600 text-white py-1 px-2 text-xs rounded">
                  0 /
                  <span v-if="complement.qtd_max">
                    {{ complement.qtd_max }}
                  </span>
                  <span v-else>1</span>
                </span>
                <span class="bg-gray-600 text-white py-1 px-2 text-xs"
                  >Obrigatório
                </span>
              </div>

              <span v-else
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-green-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  /></svg
              ></span>
            </div>

            <div
              class="border-b py-4"
              v-for="(option, index) in complement.optionals"
              :key="index"
            >
              <div class="flex px-3 mt-5 items-center">
                <div class="flex-1">
                  <p>{{ option.name }}</p>
                  <p class="text-red-600 font-medium">
                    + <span>R$</span> {{ option.price | formatprice }}
                  </p>
                </div>

                <div>
                  <div
                    class="
                      flex
                      text-gray-700
                      py-4
                      px-2
                      space-x-4
                      rounded
                      border border-gray-300
                    "
                  >
                    <button
                      class="cursor-pointer"
                      :class="{
                        disabled:
                          !option.hasOwnProperty('qtd') ||
                          complement.optionals.find(
                            (item) => item.qtd === 0 && option.id === item.id
                          ),
                      }"
                      @click="decrementComplement(indexComplement, option)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M18 12H6"
                        />
                      </svg>
                    </button>

                    <span class="font-semibold" v-if="complements.length">
                      {{ option.qtd ? option.qtd : 0 }}
                    </span>
                    <span class="font-semibold" v-else> 0 </span>

                    <button
                      class="cursor-pointer"
                      :class="{
                        disabled:
                          complement.optionals.find(
                            (item) => item.id === option.id
                          ).qtd === option.qtd_max ||
                          checksIfTotalOptionsReachedMaximumIitemOfComplement(
                            complement
                          ),
                      }"
                      @click="
                        incrementComplement(indexComplement, index, option)
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>

        <footer>
          <div
            class="flex md:justify-end justify-between md:space-x-10 space-x-3"
          >
            <div
              class="
                flex
                text-gray-700
                py-4
                px-2
                space-x-4
                rounded
                border border-gray-300
              "
            >
              <button
                class="cursor-pointer"
                @click="decreement"
                :class="{ disabled: totalItens === 1 }"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 primary__color"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M18 12H6"
                  />
                </svg>
              </button>

              <span class="font-semibold">{{ totalItens }}</span>
              <button class="cursor-pointer" @click="increment">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 primary__color"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
              </button>
            </div>

            <div
              class="
                flex flex-col
                justify-center
                items-center
                text-white
                bg__primary
                rounded
                font-semibold
                md:space-x-10 md:justify-between md:items-center md:flex-row
                w-full
                md:w-auto md:px-4
                cursor-pointer
              "
              :class="{ disabled: !releaseAddItemCart }"
              @click="addProductSelectedCart(product)"
            >
              <span>Adicionar</span>

              <span>R$ {{ totalPrice | formatprice }}</span>
            </div>
          </div>
        </footer>
      </div>
    </div>

    <!--modal alert-->
    <ModalAlert :isVisible="ModalAlert" @close-modal="ModalAlert = false" />
    <!--fim-->

    <ModaLojaClose
      :isVisible="modaLojaClose"
      :tenant="company"
      @close-modal-loja="modaLojaClose = false"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ModalAlert from "./ModalAlert.vue";
import ModaLojaClose from "./ModalLojaClose.vue";
export default {
  props: {
    productAtual: {},
    isVisible: false,
    company: "",
  },
  components: {
    ModalAlert,
    ModaLojaClose,
  },
  data() {
    return {
      total: this.productAtual.price,
      totalPriceOptions: 0,

      product: null,

      totalItens: 1,
      comment: "",
      ModalAlert: false,
      modaLojaClose: false,

      complements: [], //todos complementos
      complementsSelected: null, // complementos selecionado pelo user
    };
  },

  mounted() {
    document.addEventListener("click", this.clickOutListener);
    this.product = JSON.parse(JSON.stringify(this.productAtual));
  },

  methods: {
    closeModal() {
      this.$emit("close-modal", false);
    },

    clickOutListener(evt) {
      if (evt.target.classList.contains("modal")) {
        this.closeModal();
      }
    },

    increment() {
      this.totalItens++;
    },

    decreement() {
      if (this.totalItens <= 1) {
        return false;
      }
      this.totalItens--;
    },

    incrementComplement(indexComplement, index, option) {
      if (
        this.checksIfTotalOptionsReachedMaximumIitemOfComplement(
          this.complements[indexComplement]
        )
      ) {
        return false;
      }

      this.complements[indexComplement].optionals.filter((item) => {
        if (item.id === option.id) {
          if (item.qtd === option.qtd_max) {
            return false;
          }

          if (!item.qtd) {
            this.$set(item, "qtd", 1);
            this.totalPriceOptions += option.price;
            return;
          }

          let newQtd = item.qtd;
          newQtd += 1;

          this.$set(item, "qtd", newQtd);
          this.totalPriceOptions += option.price;
        }
      });
    },

    decrementComplement(indexComplement, option) {
      if (!option.hasOwnProperty("qtd") || option.qtd === 0) {
        return false;
      }

      if (
        !this.complements[indexComplement].optionals.find(
          (item) => item.id === option.id
        )
      ) {
        return false;
      }

      this.complements[indexComplement].optionals.filter((item) => {
        if (item.id === option.id) {
          item.qtd -= 1;
          this.totalPriceOptions -= option.price;
        }
      });
    },

    ...mapMutations({
      addProductCart: "ADD_PRODUCT_CART",
    }),

    addProductSelectedCart(prod) {
      if (!this.company.tenantOpen) {
        this.modaLojaClose = true;
        return false;
      }

      if (this.productsCart.length > 0) {
        if (this.lojaInCart.uuid !== this.company.uuid) {
          this.ShowModalAlert();
          return false;
        }
      }

      //só add itens no carrinho se itens como obrigatorio tiver ok
      if (!this.ChecksIfAllRequiredAddOnsHaveBeenSelected()) {
        return false;
      }

      this.complementsSelected = this.complements.reduce((acc, item, index) => {
        const retorno = this.checkifQtyisGreaterThanZero(item);
        if (retorno.length) {
          acc.push({ ...item, optionals: retorno });
        }
        return acc;
      }, []);

      const payload = {
        product: prod,
        complements: this.complementsSelected,
        comment: this.comment,
        qty: this.totalItens,
        totalPrice: this.totalPrice,
        loja: this.company,
      };

      if (Object.values(this.lojaInCart).length > 0) {
        payload.loja = this.lojaInCart;
      }

      this.closeModal();
      this.totalItens = 1;
      this.comment = "";
      this.totalPriceOptions = 0;

      this.addProductCart(payload);
    },

    //verifica se ja selecionou a quantidade minina do complemento obrigatorio
    CheckIfYouHaveSelectedRequiredItems(complement) {
      if (complement.required) {
        return (
          this.totalOfOptionalSelectedByComplement(complement) <
          complement.qtd_min
        );
      }
    },

    // desabilita todos opções se ja atingiu quantidade maxima do complemento
    checksIfTotalOptionsReachedMaximumIitemOfComplement(complement) {
      if (complement.customEnableSelectedQuantity === 1) {
        return this.totalOfOptionalSelectedByComplement(complement) >= 1;
      }
      return (
        this.totalOfOptionalSelectedByComplement(complement) >=
        complement.qtd_max
      );
    },

    //devolve total de opções de cada complemento selecionados
    totalOfOptionalSelectedByComplement(complement) {
      return complement.optionals.reduce((acc, item) => {
        if (item.hasOwnProperty("qtd")) {
          acc = acc + item.qtd;
        }
        return acc;
      }, 0);
    },

    //verifica Se Todos Complementos Obrigatorios Foram Selecionados
    ChecksIfAllRequiredAddOnsHaveBeenSelected() {
      const check = this.complements.filter((complement) => {
        return this.CheckIfYouHaveSelectedRequiredItems(complement);
      });

      if (check.length) {
        return false;
      }
      return check;
    },

    ShowModalAlert() {
      this.ModalAlert = true;
    },

    //checarSeQtdExisteMaiorQueZero
    checkifQtyisGreaterThanZero(complemento) {
      return complemento.optionals.filter((item) => {
        if (item.qtd && item.qtd > 0) {
          return item;
        }
      });
    },
  },
  computed: {
    ...mapState({
      productsCart: (state) => state.cart.products,
      lojaInCart: (state) => state.cart.loja,
    }),
    totalPrice() {
      return this.totalItens * this.productAtual.price + this.totalPriceOptions;
    },
    listComplements() {
      return this.complements;
    },
    releaseAddItemCart() {
      //liberar add item carrinho
      return this.ChecksIfAllRequiredAddOnsHaveBeenSelected();
    },
  },
  watch: {
    isVisible() {
      this.product = JSON.parse(JSON.stringify(this.productAtual));
      this.totalPriceOptions = 0;
      this.totalItens = 1;

      this.complements = JSON.parse(
        JSON.stringify(this.productAtual.complements)
      );
    },
  },
};
</script>

<style scoped>
.bg__fundo {
  background-color: rgba(113, 113, 113, 0.9);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;

}

.box__modal {
  overflow-y: hidden;
  left: 50%;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 50%;
  min-width: 1200px;
  max-width: 1200px;
  min-height: auto;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  height: 45vw;
  max-height: 580px;
  transition: transform 300ms ease;
}

.container__modal {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 10% 80% 10%;
  grid-template-areas: "main header" "main aside" "main footer";

  /* grid-gap: 10px; */
  padding: 2% 0;
}
.container__modal header {
  grid-area: header;
}
.container__modal main {
  grid-area: main;
  padding: 3%;
}
.container__modal aside {
  grid-area: aside;
  overflow-y: auto;
  padding: 4%;
}
.container__modal footer {
  grid-area: footer;
  padding: 0 4%;
}

.image__prod__mobile {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 10px;

}

p.name__product,
.description__product {
  text-transform: lowercase;
  line-height: 1.5rem;
}
p.name__product::first-letter,
.description__product::first-letter {
  text-transform: uppercase;
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  border-radius: 50px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #dcdcdc;
}

.disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

@media only screen and (max-width: 1280px) {
  .box__modal {
    min-width: 90%;
  }
}

@media only screen and (max-width: 768px) {


  .box__modal {
    height: 100vh;
    min-height: 100%;
    min-width: 100%;
  }
  
  .container__modal {
    grid-template-rows: 7% 33% 50% 10%;
    grid-template-areas: "header header" "aside aside" "aside aside" "footer footer";
  }
  /* .container__modal footer { */
    /* position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 20px;

    background: #fff;
    border-top: 2px solid #f5f0eb; */
  /* } */
}
</style>
