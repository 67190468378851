<template>
  <div>

    <div v-if="isVisible">
      <div
        class="
          fixed
          inset-4
          z-50
          flex
          justify-center
          items-center
          modal_loja_close
        "
      >
        <div
          class="
            bg-white
            rounded
            flex
            items-center
            flex-col
            py-8
            px-5
            relative
            w-full
            md:w-4/12
          "
        >
          <button class="absolute top-2 right-5" @click="closeModal">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-8 w-8 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>

          <div class="flex-grow flex flex-col w-full space-y-8">
            <div class="flex items-center space-x-2">
              <svg
                class="w-5 h-5 md:w-8 md:h-8 bg-yellow-400 text-white fill-current px-0.5"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"
                />
              </svg>
              <h2 class="font-semibold text-gray-700 md:text-2xl" v-if="isOpen">
              Esta loja abre às {{isOpen}}.
            </h2>
             <h2 class="font-semibold text-gray-700 md:text-2xl" v-if="!isOpen">
              Esta loja não abre hoje.
            </h2>
            </div>
            
            <div v-if="isOpen">
              <p class="text-sm md:text-base text-gray-600">
                Mas você pode aproveitar e olhar o cardápio à vontade e voltar quando a loja
                estiver aberta.
              </p>
            </div>
            <div v-if="!isOpen">
              <p class="text-sm md:text-base text-gray-600">
                Mas você pode aproveitar e olhar o cardápio à vontade de todos nossos restaurantes. 
              </p>
            </div>

            <div>
              <button
                @click.prevent="closeModal"
                class="bg-red-600 w-full p-3 text-white font-bold"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="fixed inset-0 z-40"
        style="background-color: rgba(113, 113, 113, 0.9)"
      ></div>
    </div>
    
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ['isVisible','tenant'],
  data(){
    return {
      isOpen: null,
    }
  },
  created() {
    document.addEventListener("click", this.clickOutListener);
    this.getTimeRestaurantOpens(this.tenant.uuid).then((response) => {
      if(response.openingHours){
        this.isOpen = response.openingHours[0]
      }
    })
  },
  methods: {
    ...mapActions([
      "getTimeRestaurantOpens"
    ]),
    closeModal() {
      this.$emit("close-modal-loja", false);
    },
    clickOutListener(evt) {
      if (evt.target.classList.contains("modal_loja_close")) {
        this.closeModal();
      }
    },
  },
};
</script>