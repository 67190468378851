<template>

  <div v-if="isVisible">
    <div class="fixed inset-4 z-50 flex justify-center items-center modal">
      <div
        class="bg-white rounded flex items-center flex-col py-8 px-5 relative"
      >
        <button class="absolute top-2 right-5" @click="closeModalAlert">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8 w-8 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
        <div class="flex items-center flex-col mt-10">
          <h1 class="font-semibold text-lg text-center mb-5">
            Você só pode adicionar itens de um restaurante por vez
          </h1>
          <p class="text-gray-500 font-normal mb-5">
            Deseja esvaziar a sacola?
          </p>
          <button
            class="cursor-pointer bg-red-600 py-3 px-5 mb-5 text-white rounded"
            @click="clearCart"
          >
            Sim, esvaziar a sacola!
          </button>
          <button class="cursor-pointer text-red-600" @click="closeModalAlert">
            Cancelar
          </button>
        </div>
      </div>
    </div>
    <div
      class="fixed inset-0 z-40"
      style="background-color: rgba(113, 113, 113, 0.9)"
    ></div>
  </div>
  
</template>



<script>
import { mapMutations } from "vuex";
export default {
  props: {
    isVisible: false,
    msg: "",
  },
  mounted() {
    document.addEventListener("click", this.clickOutListener);
  },
  methods: {
    ...mapMutations(["CLEAR_CART"]),

    clearCart() {
      this.CLEAR_CART();
      this.$emit("close-modal", false);

      this.$swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        icon: "success",
        title: "Sacola esvaziada",
         html: '<span class="text-gray-500 font-normal">Clique em adicionar, para adiconar seu novo item</span>',
      });

    },

    closeModalAlert() {
      this.$emit("close-modal", false);
    },
    clickOutListener(evt) {
      if (evt.target.classList.contains("modal")) {
        this.closeModalAlert();
      }
    },
  },
};
</script>

