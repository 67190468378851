<template>
  <div>
    <section class="pb-10 mt-10">
      <img src="https://ik.imagekit.io/3tf1pa6mz/Banners/banner_prod.64322725_UX8yeEB3s.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1643857939382" alt="" class="" />
    </section>


    <section class="px-4">
      <div
        class="flex flex-col md:flex-row items-start justify-between md:pb-20"
      >
        <div class="flex items-center w-full md:w-8/12">
          <img
            v-if="company.logo"
            :src="company.logo"
            :alt="company.name"
            class="w-24 rounded-full border border-gray-100 p-2"
          />
           <div
           v-else
                class="
                  w-24
                  h-24
                  rounded-full
                  border border-gray-100
                  flex flex-col
                  items-center
                  justify-center
                  bg-gray-100
                  hover:bg-gray-300
                "
              >
                <p
                  class="text-gray-500 font-extrabold"
                  style="font-family: 'Gluten', cursive"
                >
                  putz
                </p>
                
              </div>
          <div class="flex flex-col mx-4">
            <h1
              class="text-base md:text-4xl text-gray-700 font-bold line-clamp-1"
            >
              {{ company.name }}
            </h1>
            <div class="" v-if="company.evaluation">
              <StarRating
                :increment="0.5"
                :star-size="16"
                :read-only="true"
                :round-start-rating="false"
                :rating="company.evaluation"
                :fixed-points="1"
                text-class=" text-sm text-gray-700 mt-1"
              />
            </div>
            <div class="" v-else>
              <StarRating
                :increment="0.5"
                :star-size="16"
                :read-only="true"
                :round-start-rating="false"
                :rating="company.evaluation"
                text-class=" text-sm text-gray-700 mt-1"
              />
            </div>
          </div>
        </div>

        <div
          class="w-full md:w-4/12 text-center md:text-right py-5 md:p-0 md:mt-1"
        >
          <a href="#!" class="text-sm text-purple-800 font-bold">
            <router-link
              :to="{
                name: 'about_tenant',
                params: { companyUrl: company.url, uuid: company.uuid },
              }"
              >Ver mais</router-link
            >
            <span
              class="ml-3 text-xs text-gray-400"
              v-if="company.custoMminimumOrder && company.minimumOrderValue"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 inline"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
                />
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                  clip-rule="evenodd"
                />
              </svg>

              Pedido mínimo R$
              {{ company.minimumOrderValue | formatprice }}
            </span>
          </a>
          <div class="mt-2" v-if="company">
            <span
              class="text-green-600 bg-green-100 py-1 px-4"
              v-if="company.tenantOpen"
              >Aberto</span
            >
            <span class="text-red-600 bg-red-100 py-1 px-4" v-else
              >Fechado</span
            >
          </div>
        </div>
      </div>
    </section>

    <!-- sessão categorias -->
    <section>
      <div class="flex items-center">
        <Dropdown
          :itemsCategories="categories"
          @filter-by-category="filterByCategory"
        />
      </div>
    </section>
    <!-- products -->
    <section
      class="
        grid
        gap-4
        sm:grid-cols-2
        md:grid-cols-2
        mt-10
        p-4
        md:p-0
        cursor-pointer
      "
    >
      <div v-if="company.products.length === 0">
        <h3>Nenhum produto para esta categoria.</h3>
      </div>

      <div v-for="(product, index) in company.products" :key="index">
        <div
          class="
            flex
            md:h-52
            py-2
            border border-gray-100
            rounded
            relative
            card-product
          "
          @click="showModal(product)"
        >
          <div
            class="w-1/4 flex items-start md:items-center justify-center"
            style="min-width: 25%"
          >
            <img
              v-if="product.image"
              :src="product.image"
              :alt="product.description"
              class="max-w-full max-h-full border-gray-100 p-2"
  
            />

           <div
           v-else
                class="
                  w-24
                  h-24
                  rounded-full
                  border border-gray-100
                  flex flex-col
                  items-center
                  justify-center
                  bg-gray-100
                  hover:bg-gray-300
                "
              >
                <p
                  class="text-gray-500 font-extrabold"
                  style="font-family: 'Gluten', cursive"
                >
                  putz
                </p>
                
              </div>
          </div>
          <div
            class="
              flex-grow flex flex-col
              justify-between
              py-3
              px-3
              space-y-2
              text-sm text-gray-500
            "
          >
            <div class="line-clamp-2">
              <p
                class="
                  text-sm
                  font-medium
                  md:text-lg
                  text-gray-700
                  name__product
                "
              >
                {{ product.name }}
              </p>
            </div>

            <p class="text-sm text-gray-500 line-clamp-2 md:line-clamp-3 description__product">
              {{ product.description }}
            </p>
            <p class="text-sm">
              <span class="font-bold">R$</span>
              {{ product.price | formatprice }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <!--modal add prod-->
    <Modal
      :productAtual="product"
      :isVisible="modal"
      :company="company"
      @close-modal="modal = false"
    />
    <!--fim modal add prod-->
    <!--modal alert-->
    <!-- <ModalAlert
      :isVisible="ModalAlert"
      @close-modal="ModalAlert = false"
    /> -->
    <!--fim-->
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import Dropdown from "../components/Dropdown";
import Modal from "../components/Modal";
import StarRating from "vue-star-rating";

export default {
  props: ["companyUrl", "uuid"],
  components: {
    Dropdown,
    Modal,
    StarRating,
  },
  created() {
    window.scrollTo(0,1);

    
    this.getCompanySelected(this.companyUrl)
    .catch((error) => {
      this.$swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        icon: "error",
        title: "Erro ao carregar as lojas",
      });
    });
    this.getCategoriesByCompany(this.uuid);

    this.loadProducts();
  },

  data() {
    return {
      filters: {
        category: "",
      },
      modal: false,
      ModalAlert: false,
      ModalCart: false,
      product: {},
      modalVisible: false,
    };
  },

  methods: {
    ...mapActions([
      "getCompanySelected",
      "getCategoriesByCompany",
      "getProductsByCompany",
    ]),

    loadProducts() {
      const params = {
        token_company: this.uuid,
      };

      if (this.filters.category) {
        params.categories = [this.filters.category];
      }

      this.getProductsByCompany(params);
    },
    filterByCategory(category) {
      this.filters.category = category;
      this.loadProducts();
    },
    showModal(product) {
      this.product = product;
      this.modal = true;
    },
  },

  computed: {
    ...mapState({
      company: (state) => state.companies.companySelected,
      categories: (state) => state.companies.categoriesCompanySelected,
    }),
  },
};
</script>

<style scoped>
p.name__product, .description__product{
  text-transform: lowercase;
  line-height: 1.5rem;
}
p.name__product::first-letter, .description__product::first-letter {
  text-transform: uppercase;
}
</style>